import styled from 'styles/styled';

import { Button } from 'components/UI/Button';

export const Wrapper = styled.div`
  max-width: 510px;
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.titleMobile};
  font-weight: 300;
  margin-bottom: 20px;

  strong {
    font-weight: 700;
  }

  @media ${({ theme }) => theme.devices.large} {
    font-size: ${({ theme }) => theme.fontSize.titleDesktop};
  }
`;

export const StyledLink = styled(Button)`
  margin: 30px 0 0;
`;
