import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import { NAME, ROUTES } from 'constants/common';

import { StyledLink, Title, Wrapper } from './styled';

const HomePage = () => {
  const { wpPage } = useStaticQuery(graphql`
    query {
      wpPage(slug: { eq: "home" }) {
        content
        homePage {
          titleCopy
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Title>
        <strong>{NAME}&nbsp;</strong>
        <span>{wpPage.homePage.titleCopy}</span>
      </Title>
      <div dangerouslySetInnerHTML={{ __html: wpPage.content }} />
      <StyledLink as={Link} to={ROUTES.aboutUs}>
        READ MORE
      </StyledLink>
    </Wrapper>
  );
};

export default HomePage;
