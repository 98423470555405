import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import styled, { theme } from 'styles/styled';
import homeBg from 'images/background/home-bg.jpg';
import homeImg from 'images/background/home-page.png';

import SEO from 'components/SEO';
import Layout from 'components/Layout';
import { OuterWrapper } from 'components/Layout/styled';
import HomePage from 'components/HomePage';

const Wrapper = styled.div`
  position: relative;

  ${OuterWrapper} {
    &::after {
      background-image: url(${homeImg});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      bottom: 0;
      content: '';
      display: none;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;

      @media ${({ theme }) => theme.devices.medium} {
        display: block;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px 20px 200px;
    position: relative;

    &::after {
      background-image: url(${homeImg});
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto 100%;
      bottom: 0;
      content: '';
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;

      @media ${({ theme }) => theme.devices.medium} {
        display: none;
      }
    }
  }
`;

const InnerWrapper = styled.div`
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthMedium}px;
  margin: 0 auto;
  width: 100%;
`;

const Index = () => {
  const { wpPage } = useStaticQuery(graphql`
    query {
      wpPage(slug: { eq: "home" }) {
        ...MetaData
      }
    }
  `);
  wpPage.seo.opengraphUrl = '';

  return (
    <Wrapper>
      <Layout backgroundColor={theme.palette.dark} backgroundImage={homeBg}>
        <SEO metadata={wpPage || {}} />
        <InnerWrapper>
          <HomePage />
        </InnerWrapper>
      </Layout>
    </Wrapper>
  );
};

export default Index;
